import React, { useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Button,
  Icons,
  InputGroup,
  ReactSelect,
  Text,
} from "@pushpress/shared-components";
import {
  useGetPaymentMethods,
  useUpdateGetPaymentMethods,
} from "../../../api.hooks";
import { usePlanContext } from "../../../contexts/PlanContext";
import { useFormatters } from "../../../utils/hooks";
import { formatSavedPaymentLabel } from "../../../utils";
import PageContainer from "../../../components/PageContainer";
import PageTitle from "../../../components/PageTitle";
import PlanSummary from "../../../components/PlanSummary";
import PaymentButton from "../../../components/PaymentButton";
import styles from "./Payment.module.scss";

const Payment = () => {
  const { t } = useTranslation("plan");
  const { formatCurrency } = useFormatters();

  const {
    paymentMethodId,
    setPaymentMethodId,
    purchasePlan,
    amountDue,
    paying,
    paymentMethodErrors,
    calendarItem,
    bookingError,
    participant,
    retryPaymentMethod,
  } = usePlanContext();

  // plans must be purchased by payment methods that match the participant
  const { data: paymentMethods = [] } = useGetPaymentMethods(
    participant?.userUuid,
  );

  const updatePaymentMethods = useUpdateGetPaymentMethods(
    participant?.userUuid,
  );

  const selectNewPaymentMethod = (paymentMethod: PaymentMethod | null) => {
    if (paymentMethod) {
      setPaymentMethodId(paymentMethod.uuid);
      updatePaymentMethods((prev) =>
        prev ? [...prev, paymentMethod] : [paymentMethod],
      );
    }
  };

  const paymentMethod = paymentMethods.find((i) => i.uuid === paymentMethodId);

  const purchase = async () => {
    const paymentType = paymentMethod?.type!;
    purchasePlan(paymentMethodId!, paymentType, calendarItem?.uuid);
  };

  const handlePaymentMethodChange = (option: any) => {
    setPaymentMethodId(option.value);
  };

  const paymentOptions = useMemo(() => {
    return paymentMethods.map((p) => ({
      label: formatSavedPaymentLabel(p),
      value: p.uuid,
    }));
  }, [paymentMethods]);

  useEffect(() => {
    if (!paymentMethodId) {
      setPaymentMethodId(paymentMethods[0]?.uuid);
    }
  }, [paymentMethodId, paymentMethods, setPaymentMethodId]);

  const paymentMethodOptionSelected = paymentOptions.find(
    (i) => i.value === paymentMethodId,
  );

  const paymentMethodHasError = paymentMethodId
    ? !!paymentMethodErrors[paymentMethodId]
    : false;

  const payButtonText = calendarItem
    ? t("payment.payAndBook")
    : t("payment.pay", {
        total: formatCurrency(amountDue),
      });

  const loading = paying && !bookingError;

  return (
    <>
      <PageContainer>
        <PageContainer.LeftPane>
          <PlanSummary />
        </PageContainer.LeftPane>
        <PageContainer.RightPane>
          <PageTitle title={t("payment.title")} theme="light" />
          {paymentOptions.length > 0 && (
            <>
              <InputGroup
                label={t("payment.paymentMethod")}
                hideHelperText
                error={paymentMethodHasError}
              >
                <ReactSelect
                  value={paymentMethodOptionSelected}
                  options={paymentOptions}
                  onChange={handlePaymentMethodChange}
                />
              </InputGroup>
              <div className={styles.paymentMethodMessage}>
                {paymentMethodHasError && (
                  <Text color="inch-worm-600" variant="body-md">
                    <Trans
                      t={t}
                      i18nKey="payment.paymentMethodMessage"
                      components={{
                        a: <a href="#" onClick={retryPaymentMethod} />,
                      }}
                    />
                  </Text>
                )}
              </div>
            </>
          )}
          <PaymentButton selectPaymentMethod={selectNewPaymentMethod} />
          <Button
            fullWidth
            className="mt-3"
            buttonType="primary"
            size="large"
            text={payButtonText}
            iconLocation="right"
            icon={Icons.ArrowRight}
            loading={loading}
            disabled={!paymentMethodId || paymentMethodHasError}
            onClick={purchase}
          />
        </PageContainer.RightPane>
      </PageContainer>
    </>
  );
};

export default Payment;
