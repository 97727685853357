import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Icons, Text } from "@pushpress/shared-components";
import { usePlanContext } from "../../../contexts/PlanContext";
import PageContainer from "../../../components/PageContainer";
import BrandHeading from "../../../components/BrandHeading";
import CalendarItemCard from "../../../components/CalendarItemCard";
import { multiStyles } from "../../../utils";
import AccessMemberApp from "../../../components/AccessMemberApp";
import moduleStyles from "./Confirmation.module.scss";

const S = multiStyles(moduleStyles);

const Confirmation = () => {
  const { t } = useTranslation("plan");

  const { addNewParticipant, plan, isLinkedAccount, bookedCalendarItemByUuid } =
    usePlanContext();

  const bookedItems = Object.values(bookedCalendarItemByUuid);

  return (
    <PageContainer>
      <PageContainer.LeftPane>
        <BrandHeading />
        <Divider vPadding={24} />
        {bookedItems.map((item) => (
          <CalendarItemCard
            key={item.uuid}
            calendarItem={item}
            className="mb-3"
          />
        ))}
      </PageContainer.LeftPane>
      <PageContainer.RightPane>
        {plan?.confirmationMessage && (
          <div>
            <div
              className={`mb-1 ${S("confirmation-message")}`}
              dangerouslySetInnerHTML={{
                __html: plan.confirmationMessage,
              }}
            />
            <Divider className="mb-3 mt-3" />
          </div>
        )}
        {plan?.redirectUrl && !plan.confirmationMessage && (
          <>
            <Button
              buttonType="secondary"
              href={plan.redirectUrl}
              icon={Icons.ArrowRight}
              iconBold
              iconLocation="right"
              size="large"
              target="_blank"
              text={t("confirmation.moreInfo")}
            />
            <Divider vPadding={32} />
          </>
        )}
        <AccessMemberApp />
        {!isLinkedAccount && (
          <>
            <Divider className="mb-3 mt-3" />
            <Text
              component="h2"
              variant="heading-4"
              color="wolf-800"
              className="mb-2"
            >
              {t("confirmation.addSomeoneElseTitle")}
            </Text>
            <Text variant="body-lg" color="wolf-600" className="mb-3">
              {t("confirmation.addSomeoneElseDescription")}
            </Text>
            <Button
              buttonType="secondary"
              className="mb-3"
              icon={Icons.Plus}
              iconBold
              iconLocation="left"
              size="large"
              text={t("confirmation.signUpSomeoneElseTitle")}
              onClick={addNewParticipant}
            />
          </>
        )}
      </PageContainer.RightPane>
    </PageContainer>
  );
};

export default Confirmation;
