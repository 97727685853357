import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { useToggle } from "../../../../utils/hooks";
import {
  getCurrentScreen,
  getIsLinkedAccount,
  loadPlanFromStorage,
  PlanStorage,
  savePlanIntoStorage,
} from "./PlansPage.utils";

interface PlansStateOptions {
  sessionId: string;
  participantUuid: string | null;
  user: User;
}

const usePlansState = (options: PlansStateOptions) => {
  const { sessionId, participantUuid, user } = options;

  const { pathname } = useLocation();

  const activeScreen = useMemo(() => {
    return getCurrentScreen(pathname);
  }, [pathname]);

  const [savedState] = useState(() => loadPlanFromStorage(sessionId));

  const [participant, setParticipant] = useState<Participant | undefined>(
    () => {
      if (participantUuid) {
        return {
          userUuid: participantUuid,
        };
      } else if (getIsLinkedAccount(user)) {
        return user!;
      } else {
        return savedState?.participant;
      }
    },
  );

  const [paymentMethodId, setPaymentMethodId] = useState(
    savedState?.paymentMethodId,
  );

  const [payment, setPayment] = useState(savedState?.payment);

  const [skippedBooking, setSkippedBooking] = useState(
    savedState?.skippedBooking ?? false,
  );

  const [message, setMessage] = useState<string>();

  const [previousScreen, setPreviousScreen] = useState(
    savedState?.previousScreen,
  );

  const [participantType, setParticipantType] = useState(
    participantUuid ? "me" : savedState?.participantType,
  );

  const [participantTypeVisible, setParticipantTypeVisible] = useState(
    savedState?.participantTypeVisible ?? true,
  );

  const [paymentSuccessVisible, paymentSuccessActions] = useToggle(
    savedState?.paymentSuccessVisible ?? false,
  );

  const [bookingSuccessVisible, bookingSuccessActions] = useToggle(
    savedState?.bookingSuccessVisible ?? false,
  );

  const [documentsSuccessVisible, documentsSuccessActions] = useToggle(
    savedState?.documentsSuccessVisible ?? false,
  );

  const [bookedCalendarItemByUuid, setBookedCalendarItemByUuid] = useState<{
    [key: string]: CalendarItem;
  }>(savedState?.bookedCalendarItemByUuid || {});

  const [paying, payingActions] = useToggle(false);

  const [paymentMethodErrors, setPaymentMethodErrors] = useState<{
    [key: string]: boolean;
  }>({});

  const [calendarItem, setCalendarItem] = useState(savedState?.calendarItem);

  const [bookingError, setBookingError] = useState(
    savedState?.bookingError ?? false,
  );

  const [bookingErrorMessage, setBookingErrorMessage] = useState(
    savedState?.bookingErrorMessage,
  );

  const [billing, setBilling] = useState(savedState?.billing);

  const [paymentType, setPaymentType] = useState(savedState?.paymentType);

  const resetState = useCallback(() => {
    setParticipant(getIsLinkedAccount(user) ? user! : undefined);
    setParticipantTypeVisible(true);
    setParticipantType(undefined);
    setPaymentMethodId(undefined);
    setPaymentMethodErrors({});
    setPayment(undefined);
    setSkippedBooking(false);
    setPreviousScreen(undefined);
    savePlanIntoStorage(sessionId, {});
    setBookedCalendarItemByUuid({});
    paymentSuccessActions.off();
    bookingSuccessActions.off();
    documentsSuccessActions.off();
    setBookingError(false);
    setBookingErrorMessage(undefined);
  }, [
    bookingSuccessActions,
    documentsSuccessActions,
    paymentSuccessActions,
    sessionId,
    setBookedCalendarItemByUuid,
    setParticipant,
    setParticipantType,
    setParticipantTypeVisible,
    setPayment,
    setPaymentMethodId,
    setPreviousScreen,
    setSkippedBooking,
    user,
  ]);

  useEffect(() => {
    if (!activeScreen) {
      return;
    }
    const state: PlanStorage = {
      previousScreen,
      bookedCalendarItemByUuid,
      calendarItem,
    };
    if (activeScreen !== "participant") {
      state.participantTypeVisible = participantTypeVisible;
      state.participantType = participantType;
      state.participant = participant;
    }
    if (!participantTypeVisible) {
      state.participantTypeVisible = participantTypeVisible;
      state.participantType = participantType;
    }
    if (activeScreen !== "payment") {
      state.paymentMethodId = paymentMethodId;
      state.payment = payment;
    }
    if (paymentSuccessVisible) {
      state.paymentSuccessVisible = true;
      state.paymentMethodId = paymentMethodId;
      state.payment = payment;
    }
    if (documentsSuccessVisible) {
      state.documentsSuccessVisible = true;
    }
    if (activeScreen !== "booking") {
      state.skippedBooking = skippedBooking;
    }
    if (bookingSuccessVisible) {
      state.bookingSuccessVisible = true;
      state.skippedBooking = skippedBooking;
    }
    if (bookingError) {
      state.bookingError = true;
      state.bookingErrorMessage = bookingErrorMessage;
    }
    savePlanIntoStorage(sessionId, state);
  }, [
    sessionId,
    participant,
    participantType,
    participantTypeVisible,
    payment,
    paymentMethodId,
    previousScreen,
    skippedBooking,
    paymentSuccessVisible,
    bookingSuccessVisible,
    documentsSuccessVisible,
    bookedCalendarItemByUuid,
    calendarItem,
    activeScreen,
    bookingError,
    bookingErrorMessage,
  ]);

  return {
    participant,
    paymentMethodId,
    payment,
    skippedBooking,
    message,
    previousScreen,
    participantType,
    participantTypeVisible,
    paymentSuccessVisible,
    bookingSuccessVisible,
    documentsSuccessVisible,
    bookedCalendarItemByUuid,
    paying,
    paymentMethodErrors,
    calendarItem,
    bookingSuccessActions,
    documentsSuccessActions,
    payingActions,
    paymentSuccessActions,
    bookingError,
    bookingErrorMessage,
    billing,
    paymentType,
    setParticipant,
    setPaymentMethodId,
    setPayment,
    setSkippedBooking,
    setMessage,
    setPreviousScreen,
    setParticipantType,
    setParticipantTypeVisible,
    setPaymentMethodErrors,
    setCalendarItem,
    setBookedCalendarItemByUuid,
    resetState,
    setBookingError,
    setBookingErrorMessage,
    setBilling,
    setPaymentType,
  };
};

export default usePlansState;
