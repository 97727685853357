import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Icons,
  Modal,
  Text,
  useDevice,
} from "@pushpress/shared-components";
import { multiStyles } from "../../utils";
import moduleStyles from "./ConfirmBookingDialog.module.scss";

interface ConfirmBookingDialogProps {
  title?: string;
  date?: string;
  period?: string;
  visible: boolean;
  loading: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
}

const S = multiStyles(moduleStyles);

const ConfirmBookingDialog = (props: ConfirmBookingDialogProps) => {
  const { visible, loading, onClose } = props;
  const { isMobile } = useDevice();

  const handleClose = () => {
    if (!loading) {
      onClose();
    }
  };

  return isMobile ? (
    <Drawer
      anchor="bottom"
      visible={visible}
      className={S("drawer")}
      disableEscapeKeyDown
      closeModal={handleClose}
    >
      <div className={S("drawerContent")}>
        <ConfirmBookingDialogContent {...props} />
      </div>
    </Drawer>
  ) : (
    <Modal visible={visible} disableEscapeKeyDown closeModal={handleClose}>
      <div className={S("modalContent")}>
        <ConfirmBookingDialogContent {...props} />
      </div>
    </Modal>
  );
};

const ConfirmBookingDialogContent = (props: ConfirmBookingDialogProps) => {
  const { loading, title, date, period, onConfirm, onClose } = props;
  const { t } = useTranslation("plan");

  const handleClose = () => {
    if (!loading) {
      onClose();
    }
  };

  return (
    <>
      <div className={S("content")}>
        <Grid container>
          <Grid item xs={12} container justifyContent="flex-end">
            <IconButton icon={Icons.X} onClick={handleClose} />
          </Grid>
        </Grid>
        <Text component="p" variant="heading-4" color="wolf-800">
          {title}
        </Text>
        <Text component="p" variant="body-lg" color="wolf-600" className="mt-1">
          {date}
          <span className={S("bulletSeparator")}>&bull;</span>
          {period}
        </Text>
      </div>
      <Divider />
      <div className={S("action")}>
        <Button
          fullWidth
          text={t("booking.confirmBooking")}
          size="large"
          onClick={onConfirm}
          loading={loading}
        />
      </div>
    </>
  );
};

export default ConfirmBookingDialog;
