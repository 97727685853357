import React from "react";
import { multiStyles } from "../../utils";
import {
  Button,
  Divider,
  Modal,
  Text,
  useDevice,
  Drawer,
  Icons,
} from "@pushpress/shared-components";
import moduleStyles from "./BookingErrorDialog.module.scss";
import { useTranslation } from "react-i18next";

export interface BookingErrorDialogProps {
  visible: boolean;
  message?: string;
  paying: boolean;
  logout: VoidFunction;
  purchasePlanWithoutBooking: VoidFunction;
}

const S = multiStyles(moduleStyles);

const BookingErrorDialog: React.FC<BookingErrorDialogProps> = (props) => {
  const { visible } = props;

  const { isMobile } = useDevice();

  return isMobile ? (
    <Drawer anchor="bottom" visible={visible} className={S("drawer")}>
      <div className={S("drawerContent")}>
        <BookingErrorDialogContent {...props} />
      </div>
    </Drawer>
  ) : (
    <Modal visible={visible}>
      <div className={S("modalContent")}>
        <BookingErrorDialogContent {...props} />
      </div>
    </Modal>
  );
};

const BookingErrorDialogContent = (props: BookingErrorDialogProps) => {
  const { message, paying, logout, purchasePlanWithoutBooking } = props;

  const { t } = useTranslation("common");

  return (
    <>
      <div className={S("header")}>
        <div className={S("title")}>
          <Text variant="heading-4" color="wolf-800">
            {t("bookingErrorDialog.title")}
          </Text>
        </div>
        <div className={S("icon")}>
          <Icons.Error />
        </div>
      </div>
      <div className={S("body")}>
        <Text variant="body-lg" color="wolf-600">
          {message}
        </Text>
      </div>
      <div className={S("footer")}>
        <Button
          text={t("bookingErrorDialog.pay")}
          buttonType="primary"
          fullWidth
          size="large"
          loading={paying}
          onClick={purchasePlanWithoutBooking}
        />
        <Divider
          vPadding={20}
          centerElement={
            <Text variant="body-lg" color="wolf-600">
              or
            </Text>
          }
        ></Divider>
        <Button
          text={t("bookingErrorDialog.logout")}
          buttonType="secondary"
          fullWidth
          size="large"
          onClick={logout}
        />
      </div>
    </>
  );
};

export default BookingErrorDialog;
