import React, { useCallback, useEffect, useMemo } from "react";
import { useAppContext } from "../../../contexts/AppContext";
import { usePlanContext } from "../../../contexts/PlanContext";
import { useAnalytics } from "../../../components/AnalyticsProvider";
import DocumentsSuccessDialog from "../../../components/DocumentsSuccessDialog";
import SignDocumentsModal from "../../../components/SignDocumentsModal";
import { getSignDocumentsUrl } from "../../../utils";

const SUCCESSFULLY_SIGNED_MESSAGE = "successfully_signed";

const Documents = () => {
  const { subdomain } = useAppContext();
  const analytics = useAnalytics();
  const {
    plan,
    participant,
    documentsSuccessVisible,
    documentsSuccessActions,
    calendarItem,
    goToScreen,
  } = usePlanContext();

  const trackSignDocumentsEvent = useCallback(() => {
    analytics.trackEvent("document_signed", {}, true);
  }, [analytics]);

  const handleSignSuccess = useCallback(() => {
    documentsSuccessActions.on();
    trackSignDocumentsEvent();
  }, [documentsSuccessActions, trackSignDocumentsEvent]);

  const isValidOrigin = useCallback(
    (event: MessageEvent) => {
      if (process.env.NODE_ENV !== "production" && subdomain === "test") {
        return true;
      }
      const signSource = getSignDocumentsUrl(subdomain, participant!.userUuid);
      return signSource.startsWith(event.origin);
    },
    [participant, subdomain],
  );

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      if (!isValidOrigin(event)) {
        return;
      }
      if (event.data === SUCCESSFULLY_SIGNED_MESSAGE) {
        handleSignSuccess();
      }
    },
    [handleSignSuccess, isValidOrigin],
  );

  const nextScreen = useMemo(() => {
    if (calendarItem) {
      return "confirmation";
    }
    const landingPageRedirectTo = plan?.landingPageRedirectTo;
    if (landingPageRedirectTo === "confirmation") {
      return "confirmation";
    } else {
      return "booking";
    }
  }, [calendarItem, plan?.landingPageRedirectTo]);

  const handleNextClick = () => {
    goToScreen(nextScreen);
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage]);

  return (
    <>
      <SignDocumentsModal
        visible
        documents={[]}
        user={participant as User}
        blur={documentsSuccessVisible}
      />
      <DocumentsSuccessDialog
        visible={documentsSuccessVisible}
        nextScreen={nextScreen}
        onNextClick={handleNextClick}
      />
    </>
  );
};

export default Documents;
