import * as Types from "../../graphql/graphql-generated.types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetCalendarItemForPlanQueryVariables = Types.Exact<{
  clientUuid: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  uuid: Types.Scalars["String"]["input"];
}>;

export type GetCalendarItemForPlanQuery = {
  __typename?: "Query";
  getCalendarItem: {
    __typename?: "ClassItem";
    uuid: string;
    title: string;
    startDatetime: string;
    endDatetime: string;
    isAllDay: boolean;
    calendarItemType: {
      __typename?: "CalendarItemType";
      uuid: string | null;
      name: string | null;
    } | null;
  };
};

export type CalendarItemForPlanFragment = {
  __typename?: "ClassItem";
  uuid: string;
  title: string;
  startDatetime: string;
  endDatetime: string;
  isAllDay: boolean;
  calendarItemType: {
    __typename?: "CalendarItemType";
    uuid: string | null;
    name: string | null;
  } | null;
};

export type PurchaseSubscriptionPlanMutationVariables = Types.Exact<{
  input: Types.PurchaseSubscriptionInput;
}>;

export type PurchaseSubscriptionPlanMutation = {
  __typename?: "Mutation";
  purchaseSubscriptionPlan:
    | { __typename?: "SubscriptionPurchaseFailedResult"; message: string }
    | {
        __typename?: "SubscriptionPurchaseRegistrationFailedResult";
        message: string;
      }
    | {
        __typename?: "SubscriptionPurchaseRegistrationRequiresActionResult";
        invoiceUuid: string;
        subscriptionUuid: string;
        stripePaymentIntentClientSecret: string;
        stripeActionRequired: boolean;
        registration: { __typename?: "Registration"; uuid: string };
      }
    | {
        __typename?: "SubscriptionPurchaseRegistrationResult";
        invoiceUuid: string;
        subscriptionUuid: string;
        registration: { __typename?: "Registration"; uuid: string };
      }
    | {
        __typename?: "SubscriptionPurchaseRequiresActionResult";
        invoiceUuid: string;
        subscriptionUuid: string;
        status: string;
        stripePaymentIntentClientSecret: string;
        stripeActionRequired: boolean;
      }
    | {
        __typename?: "SubscriptionPurchaseResult";
        invoiceUuid: string;
        subscriptionUuid: string;
        status: string;
      };
};

export type GetSubscriptionPlanQueryVariables = Types.Exact<{
  input: Types.GetSubscriptionPlanInput;
}>;

export type GetSubscriptionPlanQuery = {
  __typename?: "Query";
  getSubscriptionPlan: {
    __typename?: "SubscriptionPlan";
    uuid: string;
    name: string;
    allowCheckins: boolean;
    hasCalendarItemTypeRestriction: boolean;
    isCheckinWindowTiedToBillingWindow: boolean;
    calendarItemTypes: Array<string> | null;
    isPublic: boolean;
    landingPageRedirectTo: string;
    active: boolean;
    description: string | null;
    type: string;
    amount: number;
    setupFee: number;
    limitCheckins: number;
    limitCheckinsType: Types.LimitCheckinsType;
    interval: number;
    intervalType: string;
    expiration: number;
    expirationType: string;
    limitQuantity: boolean;
    quantityAvailable: number;
    planTypeId: number;
    planCategoryId: number;
    isMembership: boolean;
    isTrial: boolean;
    additionalInfo: string | null;
    redirectUrl: string | null;
    redirectBookingTo: string | null;
    occurrences: number;
    confirmationMessage: string | null;
    createTimestamp: number;
    hasEntitlements: boolean;
    recurring: string | null;
    numberSessions: string | null;
    sessionsPeriod: string | null;
    sessionsPerPeriod: string | null;
    allowOpenGymCheckins: boolean;
    category: {
      __typename?: "SubscriptionPlanCategory";
      uuid: string;
      name: string;
    } | null;
    tax: {
      __typename?: "Tax";
      name: string;
      rate: number;
      uuid: string;
    } | null;
    entitlements: Array<{
      __typename?: "PlanEntitlement";
      entitlementUuid: string;
      interval: Types.EntitlementIntervalType;
      creditExpiry: number | null;
      quantity: number;
      refEntitlement: {
        __typename: "AppointmentType";
        uuid: string;
        name: string;
        color: string;
      } | null;
    }> | null;
  };
};

export type PlanFragment = {
  __typename?: "SubscriptionPlan";
  uuid: string;
  name: string;
  allowCheckins: boolean;
  hasCalendarItemTypeRestriction: boolean;
  isCheckinWindowTiedToBillingWindow: boolean;
  calendarItemTypes: Array<string> | null;
  isPublic: boolean;
  landingPageRedirectTo: string;
  active: boolean;
  description: string | null;
  type: string;
  amount: number;
  setupFee: number;
  limitCheckins: number;
  limitCheckinsType: Types.LimitCheckinsType;
  interval: number;
  intervalType: string;
  expiration: number;
  expirationType: string;
  limitQuantity: boolean;
  quantityAvailable: number;
  planTypeId: number;
  planCategoryId: number;
  isMembership: boolean;
  isTrial: boolean;
  additionalInfo: string | null;
  redirectUrl: string | null;
  redirectBookingTo: string | null;
  occurrences: number;
  confirmationMessage: string | null;
  createTimestamp: number;
  hasEntitlements: boolean;
  recurring: string | null;
  numberSessions: string | null;
  sessionsPeriod: string | null;
  sessionsPerPeriod: string | null;
  allowOpenGymCheckins: boolean;
  category: {
    __typename?: "SubscriptionPlanCategory";
    uuid: string;
    name: string;
  } | null;
  tax: { __typename?: "Tax"; name: string; rate: number; uuid: string } | null;
  entitlements: Array<{
    __typename?: "PlanEntitlement";
    entitlementUuid: string;
    interval: Types.EntitlementIntervalType;
    creditExpiry: number | null;
    quantity: number;
    refEntitlement: {
      __typename: "AppointmentType";
      uuid: string;
      name: string;
      color: string;
    } | null;
  }> | null;
};

export type GetDiscountsQueryVariables = Types.Exact<{
  input: Types.GetDiscountInput;
}>;

export type GetDiscountsQuery = {
  __typename?: "Query";
  getDiscounts: Array<{
    __typename?: "Discount";
    discountUuid: string;
    amount: number;
    name: string;
    slug: string;
    active: boolean;
    description: string;
    discountType: {
      __typename?: "DiscountType";
      discountTypeUuid: string;
      discountType: Types.DiscountTypeValue;
      discountTypeCode: string;
    };
  }>;
};

export type PlanDiscountFragment = {
  __typename?: "Discount";
  discountUuid: string;
  amount: number;
  name: string;
  slug: string;
  active: boolean;
  description: string;
  discountType: {
    __typename?: "DiscountType";
    discountTypeUuid: string;
    discountType: Types.DiscountTypeValue;
    discountTypeCode: string;
  };
};

export type GetPlansQueryVariables = Types.Exact<{
  input: Types.GetSubscriptionPlansInput;
}>;

export type GetPlansQuery = {
  __typename?: "Query";
  getSubscriptionPlans: {
    __typename?: "SubscriptionPlansPaginatedResponse";
    subscriptionPlans: Array<{
      __typename?: "SubscriptionPlan";
      uuid: string;
      name: string;
      amount: number;
      allowCheckins: boolean;
      sessionsPerPeriod: string | null;
      type: string;
      isCheckinWindowTiedToBillingWindow: boolean;
      intervalType: string;
      sessionsPeriod: string | null;
      interval: number;
      isPublic: boolean;
      active: boolean;
      hasCalendarItemTypeRestriction: boolean;
      calendarItemTypes: Array<string> | null;
      landingPageRedirectTo: string;
      numberSessions: string | null;
      expiration: number;
      expirationType: string;
      occurrences: number;
      additionalInfo: string | null;
      description: string | null;
      tax: {
        __typename?: "Tax";
        uuid: string;
        name: string;
        rate: number;
      } | null;
      category: {
        __typename?: "SubscriptionPlanCategory";
        uuid: string;
        name: string;
        active: boolean;
      } | null;
    }> | null;
  };
};

export type PlanRowFragment = {
  __typename?: "SubscriptionPlan";
  uuid: string;
  name: string;
  amount: number;
  allowCheckins: boolean;
  sessionsPerPeriod: string | null;
  type: string;
  isCheckinWindowTiedToBillingWindow: boolean;
  intervalType: string;
  sessionsPeriod: string | null;
  interval: number;
  isPublic: boolean;
  active: boolean;
  hasCalendarItemTypeRestriction: boolean;
  calendarItemTypes: Array<string> | null;
  landingPageRedirectTo: string;
  numberSessions: string | null;
  expiration: number;
  expirationType: string;
  occurrences: number;
  additionalInfo: string | null;
  description: string | null;
  tax: { __typename?: "Tax"; uuid: string; name: string; rate: number } | null;
  category: {
    __typename?: "SubscriptionPlanCategory";
    uuid: string;
    name: string;
    active: boolean;
  } | null;
};

export type PlanTaxFragment = {
  __typename?: "Tax";
  uuid: string;
  name: string;
  rate: number;
};

export type PlanCategoryFragment = {
  __typename?: "SubscriptionPlanCategory";
  uuid: string;
  name: string;
  active: boolean;
};

export type GetInvoicePreviewForPlanQueryVariables = Types.Exact<{
  getInvoicePreviewForPlanInput: Types.GetInvoicePreviewForPlanInput;
}>;

export type GetInvoicePreviewForPlanQuery = {
  __typename?: "Query";
  getInvoicePreviewForPlan: {
    __typename?: "InvoicePreview";
    total: number;
    fees: Array<{
      __typename?: "InvoiceFee";
      code: Types.InvoiceFeeCode;
      description: string | null;
      fee: number;
      rate: number | null;
    }> | null;
  };
};

export type PlanInvoicePreviewFragment = {
  __typename?: "InvoicePreview";
  total: number;
  fees: Array<{
    __typename?: "InvoiceFee";
    code: Types.InvoiceFeeCode;
    description: string | null;
    fee: number;
    rate: number | null;
  }> | null;
};

export const CalendarItemForPlanFragmentDoc = gql`
  fragment CalendarItemForPlan on ClassItem {
    uuid
    title
    startDatetime
    endDatetime
    isAllDay
    calendarItemType {
      uuid
      name
    }
  }
`;
export const PlanFragmentDoc = gql`
  fragment Plan on SubscriptionPlan {
    uuid
    name
    allowCheckins
    hasCalendarItemTypeRestriction
    isCheckinWindowTiedToBillingWindow
    calendarItemTypes
    isPublic
    landingPageRedirectTo
    active
    category {
      uuid
      name
    }
    description
    type
    amount
    setupFee
    limitCheckins
    limitCheckinsType
    interval
    intervalType
    expiration
    expirationType
    limitQuantity
    quantityAvailable
    expiration
    expirationType
    planTypeId
    planCategoryId
    isMembership
    isTrial
    additionalInfo
    redirectUrl
    redirectBookingTo
    occurrences
    confirmationMessage
    createTimestamp
    hasEntitlements
    recurring
    numberSessions
    sessionsPeriod
    sessionsPerPeriod
    limitQuantity
    quantityAvailable
    allowOpenGymCheckins
    tax {
      name
      rate
      uuid
    }
    entitlements {
      entitlementUuid
      interval
      creditExpiry
      quantity
      refEntitlement {
        ... on AppointmentType {
          __typename
          uuid
          name
          color
        }
      }
    }
  }
`;
export const PlanDiscountFragmentDoc = gql`
  fragment PlanDiscount on Discount {
    discountUuid
    amount
    name
    slug
    active
    description
    discountType {
      discountTypeUuid
      discountType
      discountTypeCode
    }
  }
`;
export const PlanTaxFragmentDoc = gql`
  fragment PlanTax on Tax {
    uuid
    name
    rate
  }
`;
export const PlanCategoryFragmentDoc = gql`
  fragment PlanCategory on SubscriptionPlanCategory {
    uuid
    name
    active
  }
`;
export const PlanRowFragmentDoc = gql`
  fragment PlanRow on SubscriptionPlan {
    uuid
    name
    amount
    allowCheckins
    sessionsPerPeriod
    type
    isCheckinWindowTiedToBillingWindow
    intervalType
    sessionsPeriod
    interval
    isPublic
    active
    hasCalendarItemTypeRestriction
    calendarItemTypes
    landingPageRedirectTo
    numberSessions
    expiration
    expirationType
    occurrences
    additionalInfo
    description
    tax {
      ...PlanTax
    }
    category {
      ...PlanCategory
    }
  }
  ${PlanTaxFragmentDoc}
  ${PlanCategoryFragmentDoc}
`;
export const PlanInvoicePreviewFragmentDoc = gql`
  fragment PlanInvoicePreview on InvoicePreview {
    total
    fees {
      code
      description
      fee
      rate
    }
  }
`;
export const GetCalendarItemForPlanDocument = gql`
  query GetCalendarItemForPlan($clientUuid: String, $uuid: String!) {
    getCalendarItem(
      getCalendarItemInput: { clientUuid: $clientUuid, uuid: $uuid }
    ) {
      ...CalendarItemForPlan
    }
  }
  ${CalendarItemForPlanFragmentDoc}
`;

/**
 * __useGetCalendarItemForPlanQuery__
 *
 * To run a query within a React component, call `useGetCalendarItemForPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarItemForPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarItemForPlanQuery({
 *   variables: {
 *      clientUuid: // value for 'clientUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetCalendarItemForPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCalendarItemForPlanQuery,
    GetCalendarItemForPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCalendarItemForPlanQuery,
    GetCalendarItemForPlanQueryVariables
  >(GetCalendarItemForPlanDocument, options);
}
export function useGetCalendarItemForPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCalendarItemForPlanQuery,
    GetCalendarItemForPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCalendarItemForPlanQuery,
    GetCalendarItemForPlanQueryVariables
  >(GetCalendarItemForPlanDocument, options);
}
export type GetCalendarItemForPlanQueryHookResult = ReturnType<
  typeof useGetCalendarItemForPlanQuery
>;
export type GetCalendarItemForPlanLazyQueryHookResult = ReturnType<
  typeof useGetCalendarItemForPlanLazyQuery
>;
export type GetCalendarItemForPlanQueryResult = Apollo.QueryResult<
  GetCalendarItemForPlanQuery,
  GetCalendarItemForPlanQueryVariables
>;
export const PurchaseSubscriptionPlanDocument = gql`
  mutation PurchaseSubscriptionPlan($input: PurchaseSubscriptionInput!) {
    purchaseSubscriptionPlan(input: $input) {
      ... on SubscriptionPurchaseResult {
        invoiceUuid
        subscriptionUuid
        status
      }
      ... on SubscriptionPurchaseRequiresActionResult {
        invoiceUuid
        subscriptionUuid
        status
        stripePaymentIntentClientSecret
        stripeActionRequired
      }
      ... on SubscriptionPurchaseFailedResult {
        message
      }
      ... on SubscriptionPurchaseRegistrationResult {
        invoiceUuid
        subscriptionUuid
        registration {
          uuid
        }
      }
      ... on SubscriptionPurchaseRegistrationRequiresActionResult {
        invoiceUuid
        subscriptionUuid
        registration {
          uuid
        }
        stripePaymentIntentClientSecret
        stripeActionRequired
      }
      ... on SubscriptionPurchaseRegistrationFailedResult {
        message
      }
    }
  }
`;
export type PurchaseSubscriptionPlanMutationFn = Apollo.MutationFunction<
  PurchaseSubscriptionPlanMutation,
  PurchaseSubscriptionPlanMutationVariables
>;

/**
 * __usePurchaseSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `usePurchaseSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseSubscriptionPlanMutation, { data, loading, error }] = usePurchaseSubscriptionPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseSubscriptionPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PurchaseSubscriptionPlanMutation,
    PurchaseSubscriptionPlanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PurchaseSubscriptionPlanMutation,
    PurchaseSubscriptionPlanMutationVariables
  >(PurchaseSubscriptionPlanDocument, options);
}
export type PurchaseSubscriptionPlanMutationHookResult = ReturnType<
  typeof usePurchaseSubscriptionPlanMutation
>;
export type PurchaseSubscriptionPlanMutationResult =
  Apollo.MutationResult<PurchaseSubscriptionPlanMutation>;
export type PurchaseSubscriptionPlanMutationOptions =
  Apollo.BaseMutationOptions<
    PurchaseSubscriptionPlanMutation,
    PurchaseSubscriptionPlanMutationVariables
  >;
export const GetSubscriptionPlanDocument = gql`
  query GetSubscriptionPlan($input: GetSubscriptionPlanInput!) {
    getSubscriptionPlan(getSubscriptionPlanInput: $input) {
      ...Plan
    }
  }
  ${PlanFragmentDoc}
`;

/**
 * __useGetSubscriptionPlanQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionPlanQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSubscriptionPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriptionPlanQuery,
    GetSubscriptionPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubscriptionPlanQuery,
    GetSubscriptionPlanQueryVariables
  >(GetSubscriptionPlanDocument, options);
}
export function useGetSubscriptionPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionPlanQuery,
    GetSubscriptionPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionPlanQuery,
    GetSubscriptionPlanQueryVariables
  >(GetSubscriptionPlanDocument, options);
}
export type GetSubscriptionPlanQueryHookResult = ReturnType<
  typeof useGetSubscriptionPlanQuery
>;
export type GetSubscriptionPlanLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionPlanLazyQuery
>;
export type GetSubscriptionPlanQueryResult = Apollo.QueryResult<
  GetSubscriptionPlanQuery,
  GetSubscriptionPlanQueryVariables
>;
export const GetDiscountsDocument = gql`
  query GetDiscounts($input: GetDiscountInput!) {
    getDiscounts(getDiscountInput: $input) {
      ...PlanDiscount
    }
  }
  ${PlanDiscountFragmentDoc}
`;

/**
 * __useGetDiscountsQuery__
 *
 * To run a query within a React component, call `useGetDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDiscountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDiscountsQuery,
    GetDiscountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDiscountsQuery, GetDiscountsQueryVariables>(
    GetDiscountsDocument,
    options,
  );
}
export function useGetDiscountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDiscountsQuery,
    GetDiscountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDiscountsQuery, GetDiscountsQueryVariables>(
    GetDiscountsDocument,
    options,
  );
}
export type GetDiscountsQueryHookResult = ReturnType<
  typeof useGetDiscountsQuery
>;
export type GetDiscountsLazyQueryHookResult = ReturnType<
  typeof useGetDiscountsLazyQuery
>;
export type GetDiscountsQueryResult = Apollo.QueryResult<
  GetDiscountsQuery,
  GetDiscountsQueryVariables
>;
export const GetPlansDocument = gql`
  query GetPlans($input: GetSubscriptionPlansInput!) {
    getSubscriptionPlans(getSubscriptionPlanInput: $input) {
      subscriptionPlans {
        ...PlanRow
      }
    }
  }
  ${PlanRowFragmentDoc}
`;

/**
 * __useGetPlansQuery__
 *
 * To run a query within a React component, call `useGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlansQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPlansQuery(
  baseOptions: Apollo.QueryHookOptions<GetPlansQuery, GetPlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlansQuery, GetPlansQueryVariables>(
    GetPlansDocument,
    options,
  );
}
export function useGetPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlansQuery,
    GetPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlansQuery, GetPlansQueryVariables>(
    GetPlansDocument,
    options,
  );
}
export type GetPlansQueryHookResult = ReturnType<typeof useGetPlansQuery>;
export type GetPlansLazyQueryHookResult = ReturnType<
  typeof useGetPlansLazyQuery
>;
export type GetPlansQueryResult = Apollo.QueryResult<
  GetPlansQuery,
  GetPlansQueryVariables
>;
export const GetInvoicePreviewForPlanDocument = gql`
  query GetInvoicePreviewForPlan(
    $getInvoicePreviewForPlanInput: GetInvoicePreviewForPlanInput!
  ) {
    getInvoicePreviewForPlan(
      getInvoicePreviewForPlanInput: $getInvoicePreviewForPlanInput
    ) {
      ...PlanInvoicePreview
    }
  }
  ${PlanInvoicePreviewFragmentDoc}
`;

/**
 * __useGetInvoicePreviewForPlanQuery__
 *
 * To run a query within a React component, call `useGetInvoicePreviewForPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicePreviewForPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicePreviewForPlanQuery({
 *   variables: {
 *      getInvoicePreviewForPlanInput: // value for 'getInvoicePreviewForPlanInput'
 *   },
 * });
 */
export function useGetInvoicePreviewForPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvoicePreviewForPlanQuery,
    GetInvoicePreviewForPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInvoicePreviewForPlanQuery,
    GetInvoicePreviewForPlanQueryVariables
  >(GetInvoicePreviewForPlanDocument, options);
}
export function useGetInvoicePreviewForPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoicePreviewForPlanQuery,
    GetInvoicePreviewForPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInvoicePreviewForPlanQuery,
    GetInvoicePreviewForPlanQueryVariables
  >(GetInvoicePreviewForPlanDocument, options);
}
export type GetInvoicePreviewForPlanQueryHookResult = ReturnType<
  typeof useGetInvoicePreviewForPlanQuery
>;
export type GetInvoicePreviewForPlanLazyQueryHookResult = ReturnType<
  typeof useGetInvoicePreviewForPlanLazyQuery
>;
export type GetInvoicePreviewForPlanQueryResult = Apollo.QueryResult<
  GetInvoicePreviewForPlanQuery,
  GetInvoicePreviewForPlanQueryVariables
>;
