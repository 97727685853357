import React from "react";
import { Text } from "@pushpress/shared-components";
import { formatRange } from "../../utils/dateUtils";

type Div = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface CalendarItemCardProps extends Div {
  calendarItem: CalendarItem;
}

const CalendarItemCard = (props: CalendarItemCardProps) => {
  const { calendarItem, ...other } = props;

  return (
    <div {...other}>
      <Text variant="heading-4" color="white" className="mb-1">
        {calendarItem.title}
      </Text>
      <Text variant="body-lg" color="white" whiteSpace="pre">
        {formatRange(
          calendarItem.startDatetime,
          calendarItem.endDatetime,
          calendarItem.isAllDay,
        )}
      </Text>
    </div>
  );
};

export default CalendarItemCard;
