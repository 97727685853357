import React from "react";
import PageContainer from "../../../components/PageContainer";
import PlanSummary from "../../../components/PlanSummary";
import { usePlanContext } from "../../../contexts/PlanContext";
import { Icons, Toast } from "@pushpress/shared-components";

const Message = () => {
  const { planInvalidMessage } = usePlanContext();

  if (!planInvalidMessage) {
    return null;
  }

  return (
    <PageContainer>
      <PageContainer.LeftPane>
        <PlanSummary />
      </PageContainer.LeftPane>
      <PageContainer.RightPane>
        <Toast
          title={planInvalidMessage.title}
          message={planInvalidMessage.message}
          icon={Icons.Alert}
          iconPosition="center"
        />
      </PageContainer.RightPane>
    </PageContainer>
  );
};

export default Message;
