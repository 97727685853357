import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import moduleStyles from "./BookingButton.module.scss";
import { Text, multiStyles } from "@pushpress/shared-components";

type ButtonElement = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface BookingButtonProps extends ButtonElement {
  title: string;
  subtitle?: string;
  allDay?: boolean;
  startTime: string;
  endTime: string;
  booked: boolean;
}

const S = multiStyles(moduleStyles);

const BookingButton: React.FC<BookingButtonProps> = (props) => {
  const { title, allDay, startTime, endTime, booked, subtitle, ...other } =
    props;
  const { t } = useTranslation("common");
  const rootClassName = S("root", [booked, "booked"]);
  const timeRange = allDay
    ? t("components.allDay")
    : `${moment(startTime).format("h:mm a")} - ${moment(endTime).format(
        "h:mm a",
      )}`;

  return (
    <button {...other} className={rootClassName} disabled={booked}>
      <div className={S("body")}>
        <Text variant="body-lg" className={S("title")}>
          {title}
        </Text>
        <Text variant="body-lg" color="wolf-600" className={S("subTitle")}>
          {timeRange}
        </Text>
        <Text variant="body-lg" color="wolf-600">
          {subtitle}
        </Text>
      </div>
      {booked && <div className={S("bookedTag")}>{t("components.booked")}</div>}
    </button>
  );
};

export default BookingButton;
