import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Text } from "@pushpress/shared-components";
import SuccessDialog from "../SuccessDialog";

export interface BookingSuccessDialogProps {
  visible: boolean;
  allSessionsBooked?: boolean;
  name?: string;
  date?: string;
  period?: string;
  onDoneBooking: VoidFunction;
  onBookAnotherSession: VoidFunction;
}

const BookingSuccessDialog: React.FC<BookingSuccessDialogProps> = (props) => {
  const {
    visible,
    name,
    date,
    period,
    allSessionsBooked,
    onDoneBooking,
    onBookAnotherSession,
  } = props;

  const { t } = useTranslation("common");

  return (
    <SuccessDialog
      visible={visible}
      title={t("bookingSuccessDialog.bookingConfirmed")}
      actions={
        allSessionsBooked ? (
          <Button
            text={t("bookingSuccessDialog.continue")}
            buttonType="primary"
            fullWidth
            size="large"
            onClick={onDoneBooking}
          />
        ) : (
          <>
            <Button
              text={t("bookingSuccessDialog.doneWithBooking")}
              buttonType="primary"
              fullWidth
              size="large"
              onClick={onDoneBooking}
            />
            <Divider
              vPadding={20}
              centerElement={
                <Text variant="body-lg" color="wolf-600">
                  {t("bookingSuccessDialog.or")}
                </Text>
              }
            />
            <Button
              text={t("bookingSuccessDialog.bookAnotherSession")}
              buttonType="secondary"
              fullWidth
              size="large"
              onClick={onBookAnotherSession}
            />
          </>
        )
      }
    >
      <Text component="p" variant="body-lg" color="wolf-600" className="mb-1">
        {name}
      </Text>
      {date && (
        <Text component="span" variant="body-lg" color="wolf-600">
          {date}
        </Text>
      )}
      {date && period && (
        <Text component="span" color="wolf-600" className="mr-1 ml-1">
          &bull;
        </Text>
      )}
      {period && (
        <Text component="span" variant="body-lg" color="wolf-600">
          {period}
        </Text>
      )}
    </SuccessDialog>
  );
};

export default BookingSuccessDialog;
