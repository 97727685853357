import { SubscriptionPurchaseResponse } from "../../../../graphql/graphql-generated.types";
import { mountDayYearDateToIso } from "../../../../utils";
import {
  calculateDiscount,
  calculateTaxAmount,
  calculateTotalPrice,
} from "../../../../utils/priceUtils";
import {
  decodeFromBase64,
  encodeToBase64,
} from "../../../../utils/stringUtils";
import {
  CalendarItemForPlanFragment,
  PlanDiscountFragment,
  PlanFragment,
} from "../../PlansPage-generated.hooks";

export const getMessageIdIfPlanIsNotValid = (plan: PlanFragment) => {
  if (!plan.active) {
    return "planIsInactive";
  }
  return undefined;
};

export const getQueryOptions = (options?: string | string[]) => {
  if (!options || options.length === 0) {
    return { discountSlug: undefined, startDate: undefined };
  }
  try {
    const content = Array.isArray(options) ? options[0] : options;
    const optionsObj = parseBase64(content);
    const date = optionsObj.start_date as string;
    const discountSlug = optionsObj.discount_slug as string;
    const startDate = mountDayYearDateToIso(date);
    return {
      discountSlug,
      startDate,
    };
  } catch (e) {
    console.log("Error parsing query options:", options, "\nError:", e);
    return { discountSlug: undefined, startDate: undefined };
  }
};

export const calculatePlanAmount = (
  plan?: PlanFragment,
  planDiscount?: PlanDiscountFragment,
) => {
  if (!plan) {
    return 0;
  }
  const amountValue = plan.amount || 0;
  const discount = planDiscount?.active ? planDiscount : undefined;
  const discountValue = discount?.amount || 0;
  const setupFeeValue = plan.setupFee || 0;
  const taxValue = plan.tax?.rate || 0;
  const isDiscountPercent = discount?.discountType?.discountTypeCode === "%";
  return calculateTotalPrice(
    amountValue,
    setupFeeValue,
    isDiscountPercent,
    discountValue,
    taxValue,
  );
};

export const calculatePlanTax = (
  plan?: PlanFragment,
  planDiscount?: PlanDiscountFragment,
) => {
  if (!plan) {
    return 0;
  }
  const amountValue = plan.amount || 0;
  const discount = planDiscount?.active ? planDiscount : undefined;
  const discountValue = discount?.amount || 0;
  const setupFeeValue = plan.setupFee || 0;
  const taxValue = plan.tax?.rate || 0;
  const isDiscountPercent = discount?.discountType?.discountTypeCode === "%";
  const result = calculateTaxAmount(
    amountValue,
    setupFeeValue,
    isDiscountPercent,
    discountValue,
    taxValue,
  );
  return result;
};

export const calculatePlanDiscount = (
  plan?: PlanFragment,
  planDiscount?: PlanDiscountFragment,
) => {
  if (!plan) {
    return 0;
  }
  const amountValue = Number(plan.amount || 0);
  const discount = planDiscount?.active ? planDiscount : undefined;
  if (!discount) {
    return 0;
  }
  const discountValue = discount?.amount || 0;
  const isDiscountPercent = discount?.discountType?.discountTypeCode === "%";
  return calculateDiscount(amountValue, isDiscountPercent, discountValue);
};

export const getIsLinkedAccount = (user: User | null) => {
  return !!user?.parentUserId;
};

const parseBase64 = (content: string) => {
  const decoded = Buffer.from(content as any, "base64")?.toString();
  return JSON.parse(decoded);
};

export interface PlanStorage {
  participant?: Participant;
  payment?: SubscriptionPurchaseResponse;
  paymentMethodId?: string;
  skippedBooking?: boolean;
  previousScreen?: PlanScreen;
  participantType?: ParticipantType;
  participantTypeVisible?: boolean;
  paymentSuccessVisible?: boolean;
  bookingSuccessVisible?: boolean;
  documentsSuccessVisible?: boolean;
  bookedCalendarItemByUuid?: {
    [key: string]: CalendarItem;
  };
  calendarItem?: CalendarItemForPlanFragment;
  bookingError?: boolean;
  bookingErrorMessage?: string;
  billing?: string;
  paymentType?: PaymentMethodType | "free";
}

export const loadPlanFromStorage = (sessionId: string) => {
  const state = sessionStorage.getItem(`planState_${sessionId}`);
  if (state) {
    const decoded = decodeFromBase64(state);
    return JSON.parse(decoded) as PlanStorage;
  }
};

export const savePlanIntoStorage = (sessionId: string, state: PlanStorage) => {
  const encoded = encodeToBase64(JSON.stringify(state));
  sessionStorage.setItem(`planState_${sessionId}`, encoded);
};

export const getCurrentScreen = (pathname: string) => {
  const partsQuery = pathname.split("?");
  return partsQuery[0].split("/").slice(-1)[0] as PlanScreen;
};
