import React, { useCallback, useMemo, useState } from "react";
import { format, getTime, parse } from "date-fns";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";

import {
  Button,
  Icons,
  DayPicker,
  Loader,
  Toast,
  Grid,
  Text,
} from "@pushpress/shared-components";
import { useAppMutation, useToggle } from "../../../utils/hooks";
import { extractError, multiStyles } from "../../../utils";
import { useAppContext } from "../../../contexts/AppContext";
import { usePlanContext } from "../../../contexts/PlanContext";
import { useGetCalendar, useGetCalendarByRange } from "../../../api.hooks";
import { getCalendarAvailability, postScheduleCalendar } from "../../../api";
import PageContainer from "../../../components/PageContainer";
import BrandHeading from "../../../components/BrandHeading";
import { useToast } from "../../../components/ToastProvider";
import ConfirmBookingDialog from "../../../components/ConfirmBookingDialog";
import BookingButton from "../../../components/BookingButton";
import BookingSuccessDialog from "../../../components/BookingSuccessDialog";
import { useAnalytics } from "../../../components/AnalyticsProvider";
import { getExpiredDate, isPlanExpired } from "./Booking.utils";
import moduleStyles from "./Booking.module.scss";

const S = multiStyles(moduleStyles);

const PUNCH_CARD_TYPE = "P";

const Booking = () => {
  const { t } = useTranslation("plan");
  const { setErrorToast } = useToast();
  const { client } = useAppContext();
  const analytics = useAnalytics();
  const {
    goToScreen,
    plan,
    participant,
    subscriptionUuid,
    setSkippedBooking,
    bookingSuccessVisible,
    bookingSuccessActions,
    bookedCalendarItemByUuid,
    addBookedCalendarItem,
    today,
    calendarEndDate,
  } = usePlanContext();

  const [selectedSlot, setAppointmentSlot] = useState<string>();
  const [confirmVisible, confirmActions] = useToggle(false);
  const [booking, bookingActions] = useToggle(false);
  const [date, setDate] = useState<number>();
  const { data: calendarRange, isLoading: isCalendarRangeLoading } =
    useGetCalendarByRange(
      today.getTime(),
      calendarEndDate.getTime(),
      plan,
      client.timezone,
    );
  const initialParsedDate = parse(
    calendarRange?.[0]?.start_datetime || "",
    "yyyy-MM-dd HH:mm:ss",
    new Date(),
  );
  const initialTimestamp = getTime(initialParsedDate);
  const {
    data: calendar,
    isLoading: isCalendarLoading,
    isRefetching: isCalendarRefetching,
    refetch: refetchCalendar,
  } = useGetCalendar(date || initialTimestamp, plan, client.timezone, {
    enabled: !!(date || initialTimestamp),
  });
  const { mutateAsync: scheduleCalendar } = useAppMutation(
    postScheduleCalendar,
    { showMessageOnError: false },
  );

  const skip = () => {
    setSkippedBooking(true);
    goToScreen("confirmation");
  };

  const onSelectDate = useCallback(
    (selectedDate: number) => {
      setDate(selectedDate);
      setAppointmentSlot(undefined);
    },
    [setDate, setAppointmentSlot],
  );

  const selectSlot = useCallback(
    async (slot: string) => {
      setAppointmentSlot(slot);
      confirmActions.on();
    },
    [confirmActions],
  );

  const trackBookSessionEvent = () => {
    analytics.trackEvent("class_booked", {}, true);
  };

  const bookSelectedSession = async () => {
    bookingActions.on();
    if (!subscriptionUuid) {
      throw new Error("Invalid payment");
    }
    try {
      const data = await getCalendarAvailability(
        client?.uuid!,
        selectedSlot!,
        subscriptionUuid,
      );
      if (data.limit === 0) {
        throw new Error(t("booking.classNotAvailable"));
      }
      const calendar = await scheduleCalendar({
        user_id: participant?.userUuid!,
        calendar_item_id: selectedSlot!,
        subscription_id: subscriptionUuid,
        source: "landing page",
      });
      addBookedCalendarItem({
        uuid: calendar.calendar_item.uuid,
        title: calendar.calendar_item.title,
        startDatetime: calendar.calendar_item.start_datetime,
        endDatetime: calendar.calendar_item.end_datetime,
        isAllDay: Boolean(calendar.calendar_item.is_all_day),
      });
      setSkippedBooking(false);
      bookingSuccessActions.on();
      trackBookSessionEvent();
    } catch (error) {
      setErrorToast({
        message: extractError(error),
      });
    } finally {
      bookingActions.off();
    }
  };

  const handleDoneBooking = () => {
    goToScreen("confirmation");
  };

  const handleBookAnotherSession = () => {
    bookingSuccessActions.off();
    refetchCalendar();
  };

  const dateErrorMessage = useMemo(() => {
    if (date && isPlanExpired(plan, date)) {
      const expiredDate = getExpiredDate(plan)!;
      const formattedDate = format(expiredDate, "EEEE, LLLL d, uuuu.");
      return {
        title: t("booking.expireDateTitle"),
        message: t("booking.expireDateDesc", { date: formattedDate }),
      };
    }
    return null;
  }, [plan, date, t]);

  const availabilitySlots = useMemo(() => {
    if (dateErrorMessage) {
      return (
        <Toast
          icon={Icons.Alert}
          iconColor="warning"
          title={dateErrorMessage.title}
          message={dateErrorMessage.message}
        />
      );
    }
    if (calendar?.length === 0) {
      return (
        <Toast
          message={t("booking.noAvailability")}
          icon={Icons.Information}
          iconPosition="center"
        />
      );
    }
    return calendar?.map((slot) => {
      const booked = slot.registrations.some(
        (item) => item.customer.id === participant?.userUuid,
      );
      return (
        <BookingButton
          booked={booked}
          title={slot.title}
          startTime={slot.start_datetime}
          endTime={slot.end_datetime}
          key={slot.uuid}
          onClick={() => selectSlot(slot.uuid)}
        />
      );
    });
  }, [calendar, dateErrorMessage, participant, selectSlot, t]);

  const activeSlot = useMemo(() => {
    return selectedSlot
      ? calendar?.find((i) => i.uuid === selectedSlot)
      : undefined;
  }, [calendar, selectedSlot]);

  const confirmTitle = activeSlot?.title;
  const confirmDate = moment(date).utc().format("dddd, MMMM D");
  const startTime = moment(activeSlot?.start_datetime).format("h:mm a");
  const endTime = moment(activeSlot?.end_datetime).format("h:mm a");
  const confirmPeriod = `${startTime} - ${endTime}`;
  const bookedCount = Object.keys(bookedCalendarItemByUuid).length;
  const allSessionsBooked =
    plan.type === PUNCH_CARD_TYPE && bookedCount >= Number(plan.numberSessions);

  return (
    <>
      <PageContainer>
        <PageContainer.LeftPane>
          <BrandHeading />
        </PageContainer.LeftPane>
        <PageContainer.RightPane>
          {isCalendarRangeLoading ? (
            <Grid container alignItems="center" justifyContent="center">
              <Loader />
            </Grid>
          ) : (
            <>
              <Grid container className="mb-4">
                <Grid item xs={10}>
                  <Text variant="heading-4">
                    <Trans
                      t={t}
                      i18nKey="booking.nextStep"
                      components={{ bold: <strong /> }}
                    />
                  </Text>
                </Grid>
                <Grid item xs={2} container justifyContent="flex-end">
                  <Button
                    buttonType="secondary"
                    text={t("booking.skip")}
                    size="small"
                    onClick={skip}
                  />
                </Grid>
              </Grid>
              <DayPicker
                theme="light"
                onSelectDate={onSelectDate}
                initialDate={moment(calendarRange?.[0]?.start_datetime)}
                titleVariant="heading-4"
              />
              <div className={S("timezone-row")}>
                <Icons.Globe className={S("icon-globe")} />
                <p className={S("timezone-description")}>{client.timezone}</p>
              </div>
              <div className={S("available-slots")}>
                {isCalendarLoading || isCalendarRefetching ? (
                  <Loader />
                ) : (
                  availabilitySlots
                )}
              </div>
            </>
          )}
        </PageContainer.RightPane>
      </PageContainer>
      <ConfirmBookingDialog
        title={confirmTitle}
        date={confirmDate}
        period={confirmPeriod}
        visible={confirmVisible}
        loading={booking}
        onClose={confirmActions.off}
        onConfirm={bookSelectedSession}
      />
      <BookingSuccessDialog
        visible={bookingSuccessVisible}
        name={confirmTitle}
        date={confirmDate}
        period={confirmPeriod}
        allSessionsBooked={allSessionsBooked}
        onDoneBooking={handleDoneBooking}
        onBookAnotherSession={handleBookAnotherSession}
      />
    </>
  );
};

export default Booking;
