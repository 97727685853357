import { useRouter } from "next/router";
import { useMemo } from "react";
import { getQueryOptions } from "./PlansPage.utils";

const usePlansPreSelectedParams = () => {
  const { query } = useRouter();

  const { options, ["calendar-item"]: calendarItemUuidParam } = query as {
    options?: string;
    ["calendar-item"]?: string;
  };

  // Participant uuid is grabbed from session storage for safety purpose, we don't wanna to expose this kind of info in the URL.
  // See more details here: https://pushpress.atlassian.net/browse/PLAT-728
  const participantUuid = window.sessionStorage.getItem("participantUuid");

  const { startDate } = useMemo(() => {
    return getQueryOptions(options);
  }, [options]);

  return {
    participantUuid,
    calendarItemUuidParam,
    startDate,
  };
};

export default usePlansPreSelectedParams;
