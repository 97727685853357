import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import { Button, Text } from "@pushpress/shared-components";
import { useFormatters } from "../../utils/hooks";
import { removeTimezone } from "../../utils/locale";
import { CalendarItemForPlanFragment } from "../../pages/plans/PlansPage-generated.hooks";
import { useAnalytics } from "../AnalyticsProvider";
import SuccessDialog from "../SuccessDialog";

export interface PaymentSuccessDialogProps {
  visible: boolean;
  name: string;
  price: number;
  nextScreen: "documents" | "booking" | "confirmation";
  entityUuid?: string;
  referredFromStaffKiosk?: boolean;
  calendarItem?: CalendarItemForPlanFragment;
  onNextClick: VoidFunction;
}

const PaymentSuccessDialog: React.FC<PaymentSuccessDialogProps> = (props) => {
  const {
    visible,
    name,
    price,
    entityUuid,
    referredFromStaffKiosk = false,
    nextScreen,
    calendarItem,
    onNextClick,
  } = props;
  const analytics = useAnalytics();
  const { t } = useTranslation("common");
  const { formatCurrency } = useFormatters();

  const title = useMemo(() => {
    if (calendarItem) {
      return t("paymentSuccessDialog.bookingConfirmed");
    }
    return price > 0
      ? t("paymentSuccessDialog.purchaseComplete")
      : t("paymentSuccessDialog.signUpComplete");
  }, [calendarItem, price, t]);

  const text = useMemo(() => {
    if (calendarItem) {
      const { startDatetime, title } = calendarItem;
      const date = new Date(removeTimezone(startDatetime));
      const formattedDate = format(date, "EEE, MMM d"); // "Wed, Sep 21"
      const formattedTime = format(date, "h:mma").toLowerCase(); // "2:30pm"
      return `${title} - ${formattedDate} @ ${formattedTime}`;
    }
    return name;
  }, [calendarItem, name]);

  const nextText = t(`paymentSuccessDialog.${nextScreen}`);

  // Track event if a user arrived at this LP from the
  // Staff App Kiosk QR code and "purchased" the plan
  useEffect(() => {
    if (visible && referredFromStaffKiosk && entityUuid) {
      analytics.trackEvent(
        "kiosk_referral_purchased",
        {
          uuid: entityUuid,
          price: price,
        },
        true,
      );
    }
  }, [analytics, referredFromStaffKiosk, visible, price, entityUuid]);

  return (
    <SuccessDialog
      visible={visible}
      title={title}
      actions={
        <Button
          text={nextText}
          buttonType="primary"
          fullWidth
          size="large"
          onClick={onNextClick}
        />
      }
    >
      <Text variant="body-lg" color="wolf-600">
        {text}
      </Text>
      {price > 0 && (
        <Text variant="body-lg" color="wolf-600" className="mt-1">
          {t("paymentSuccessDialog.chargeMessage", {
            price: formatCurrency(price),
          })}
        </Text>
      )}
    </SuccessDialog>
  );
};

export default PaymentSuccessDialog;
