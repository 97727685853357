import { SubscriptionPurchaseResponse } from "../graphql/graphql-generated.types";

export const getSubscriptionUuid = (payment?: SubscriptionPurchaseResponse) => {
  if (!payment) {
    return undefined;
  }
  let result: string | undefined = undefined;
  if (
    payment.__typename ===
      "SubscriptionPurchaseRegistrationRequiresActionResult" ||
    payment.__typename === "SubscriptionPurchaseRegistrationResult" ||
    payment.__typename === "SubscriptionPurchaseRequiresActionResult" ||
    payment.__typename === "SubscriptionPurchaseResult"
  ) {
    result = payment.subscriptionUuid;
  }
  return result;
};
